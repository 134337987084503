import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import SbEditable from 'storyblok-react'

import Layout from "../components/layout"
import Seo from "../components/seo"
import DynamicComponent from "../components/dynamicComponent"
import useStoryblok from "../lib/storyblok"

const IndexPage = ({ data, location }) => {
  let story = data.storyblokEntry
  story = useStoryblok(story, location)

  const components = story.content.body.map(blok => {
    return (<DynamicComponent blok={blok} key={blok._uid} />)
  })

  return (
  <Layout>
    <SbEditable content={story.content}>
      <Seo
        title={ story.name }
        description="I'm a DevRel Engineer at Storyblok. Also, I organize a free tech skill and knowledge-sharing community, Lilac."
        image={ data.site.image }
      />
        <div className="grid md:grid-cols-2 grid-rows-1">
          <div
            className="py-28"
            data-sal="slide-down"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <p className="hero-greeting text-2xl">{ story.content.title }</p>
            <h1 className="header-gradetion hero-headline text-5xl font-black text-center pt-3 pb-10">
              { story.content.heroHeadline }
            </h1>
            <p className="hero-sub">{ story.content.heroSub }</p>
          </div>
          <StaticImage
            src="../images/new-profile.JPEG"
            width={600}
            quality={100}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="profile"
            style={{
              clipPath: `polygon(100% 0%, 87% 55%, 75% 100%, 0 100%, 25% 0)`
            }}
            className="hero-img p-5"
          />
        </div>
        { components }
    </SbEditable>
  </Layout>
)}

export default IndexPage

export const query = graphql`
  query HomeQuery {
    storyblokEntry(full_slug: {eq: "home"}) {
      content
      name
    }
    site {
      siteMetadata {
        image
      }
    }
  }
`